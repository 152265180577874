
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import CreateUi from "@/components/Core/Create/CreateUI.vue";

import { ISocietyService } from "@/services";
import _ from "lodash";

@Component({
  components: {
    CreateUi,
  },
})
export default class SocietyCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  forbiddenNames!: string[];

  get societyService() {
    return this.container.resolve<ISocietyService>(S.SOCIETY_SERVICE);
  }

  loading: boolean = false;
  name: string | null = null;

  async create() {
    try {
      this.loading = true;
      const societyCreated = await this.societyService.create({
        name: this.name!,
      });
      this.$emit("create", societyCreated);
    } finally {
      this.loading = false;
    }
  }

  isAllowed(name: string) {
    return (
      _.findIndex(
        this.forbiddenNames,
        (s) => s.toUpperCase() == name.toUpperCase()
      ) != -1
    );
  }
}
